<template>
  <div class="manage-areas animatedBox">
    <div class="container fluid">
      <!-- ============================ Filter ============================ -->
      <filter-panel
        ref="filterPanel"
        v-model="isFilterActive"
        @filter="filterData()"
        @clear="clearFilter()"
      >
        <div class="row p-2">
          <fd-input
            v-model="filter['name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Area"
            name="filterAreaName"
            type="text"
            @keyup.native.enter="filterData()"
          >
          </fd-input>
          <fd-input
            v-model="filter['city:name[partial]']"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="City"
            name="filterCityName"
            type="text"
            @keyup.native.enter="filterData()"
          >
          </fd-input>
        </div>
      </filter-panel>
      <!-- ============================ Table ============================ -->
      <div class="card">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="areasTableColumns"
          :rows="areasTableData"
          :totalRows="areasTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="toAddArea">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button class="btn mr-1" @click="toEditArea(props.row.id)">
                <i class="fas fa-pen"></i>
              </button>
              <!-- TODO: Wait for delete API -->
              <button class="btn danger" @click="deleteAreas(props.row.id)">
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-areas",
  mixins: [vueGoodTable],
  components: {},
  data() {
    return {
      areasTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        },
        {
          label: "City",
          field: "city.name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      areasTableData: [],
      areasTablePagination: {},
      isFilterActive: false,
      filter: {
        "name[partial]": "",
        "city:name[partial]": ""
      }
    };
  },
  mounted() {
    this.getAllAreas({ limit: this.tableParams.perPage });
  },
  methods: {
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllAreas();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllAreas();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllAreas();
    },
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllAreas();
    },
    toAddArea() {
      this.$router.push({ name: "ManageAreasAdd" });
    },
    toEditArea(id) {
      this.$router.push({ name: "ManageAreasEdit", params: { id: id } });
    },

    // ============================= API Related ===============================
    async getAllAreas() {
      this.$store.commit("setIsLoading", true);

      let filteredParam = this.$cleanQueryParam(this.filter);

      let data = await this.$store.dispatch("manageAreas/getAllAreas", {
        ...filteredParam,
        page: this.tableParams.page,
        limit: this.tableParams.perPage,
        include: "city"
      });

      this.areasTableData = this._.cloneDeep(data.data);
      this.areasTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    async deleteAreas(id) {
      const c = confirm("Are you sure to delete?");

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.$store.dispatch("manageAreas/deleteAreas", id);

          this.$store.commit("setIsLoading", false);
          this.getAllAreas();
        } catch (error) {
          alert("An unexpected error occured. Please try again later.");
          this.$store.commit("setIsLoading", false);
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-areas {
}
</style>
